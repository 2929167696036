import { useQuery, useQueryClient } from '@tanstack/vue-query'

export const findChartOfAccount = (id, chartOfAccounts) => {
    let result = null
    unref(chartOfAccounts).some((chartOfAccount) => {
        return (
            (`${chartOfAccount.id}` === `${id}` && (result = chartOfAccount)) ||
            (result = findChartOfAccount(id, chartOfAccount.children || []))
        )
    })

    return result
}

export function useTreeChartOfAccounts({ fromDepth } = {}) {
    const chartOfAccounts = computed(() => data.value ?? [])

    const { execute } = useApi('/api/chart-of-accounts/tree', 'GET')

    const getTreeChartOfAccounts = () => {
        return execute({
            queries: {
                'from-depth': fromDepth,
            },
        })
    }

    const { data, isLoading } = useQuery({
        queryKey: ['chart-of-accounts-tree', fromDepth],
        queryFn: () => getTreeChartOfAccounts(),
        staleTime: Infinity,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    return { chartOfAccounts, isLoading, getTreeChartOfAccounts }
}

export function useFlatChartOfAccounts(
    depth = 0,
    type = 'income',
    only = false
) {
    const { execute } = useApi('/api/chart-of-accounts/get-all-flat', 'GET')

    const queryClient = useQueryClient()
    const chartOfAccounts = computed(() => data.value ?? [])

    const getTreeChartOfAccounts = () => {
        let queries = {
            depth: depth,
        }

        if (only) {
            queries = {
                ...queries,
                only_type: type,
            }
        } else {
            queries = {
                ...queries,
                type,
            }
        }

        return execute({
            queries,
        })
    }

    const { data, isLoading } = useQuery({
        queryKey: ['chart-of-accounts-flat', depth, type, only],
        queryFn: () => getTreeChartOfAccounts(),
        staleTime: Infinity,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    const invalidate = () => {
        queryClient.invalidateQueries({
            queryKey: ['chart-of-accounts-flat', depth, type, only],
        })
    }

    return { chartOfAccounts, isLoading, invalidate, getTreeChartOfAccounts }
}

export function useCOAModal() {
    const { invalidate } = useFlatChartOfAccounts(2)
    const selectedField = ref(null)
    const showAddModal = ref(false)

    const onCloseAddModal = () => {
        showAddModal.value = false
    }
    const onFinished = (chartOfAccount) => {
        onCloseAddModal()
        invalidate()
        selectedField.value.chart_of_account_id = chartOfAccount.id
        selectedField.value = null
    }
    const onOpenAddModal = (field) => {
        showAddModal.value = true
        // When opening Add COA modal, we need to set the selectedField to the field that is being edited
        selectedField.value = field
    }

    return {
        showAddModal,
        selectedField,
        onOpenAddModal,
        onCloseAddModal,
        onFinished,
    }
}
